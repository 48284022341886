import AnnotationInterface from '../components/AnnotationInterface';
import Navbar from 'react-bootstrap/Navbar';
import {Container, Row, Col} from 'react-bootstrap'
import './Main.css';

function Main() {
    return (
        <div className="main">
            <Navbar expand="lg" variant="light" bg="light">
                <Container fluid="sm">
                    <Row>
                        <Col>
                            <img src={require('../assets/side_logo.png')} style={{height: "40px", margin: "15px 0 15px 0"}} />
                            <span style={{position: "relative", bottom: "-5.5px", textTransform: "uppercase", margin: "0 0 0 15px"}}> Which source is better for the citation?</span>
                        </Col>
                    </Row>
                </Container>
            </Navbar>
            <AnnotationInterface></AnnotationInterface>
        </div>
    );
}

export default Main;
