import { Button, Navbar, Form } from 'react-bootstrap';
import { content } from './assets/content.js';
import { Modal } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import './App.css';

const AUTHENTICATION_URL = 'https://verifier.sideeditor.com/api/login';

function TOSContent(props) {
  return (
    <div>
      <p>
        By using Side,
        you agree to be bound by Meta Platform Inc.’s Terms of Service(available at {props.facebookTosLink}).
        <br/>
        <br/>To the extent you submit any information, content, or feedback when interacting with Side ("Content"), you hereby grant Meta Platform Inc.
        (and represent and warrant that you have the right to grant) an irrevocable, non-exclusive, royalty-free and fully-paid-up, worldwide license to reproduce, distribute, publicly display and perform, prepare derivative works of, incorporate into other works and otherwise use and exploit such Content, and to grant sublicenses of the foregoing rights.
        <br/><br/>As part of your use of Side, we will collect and store your Wikipedia User ID in accordance with the Meta Platform Inc.
        Data Policy (available at {props.facebookDataPolicyLink}).
      </p>
    </div>
  );
}

function Login(props) {
  return (
    <>
      <div className="button-container">
        <Button variant="primary" type="submit" className="btn-lg"
                onClick={ () => {
           window.open(AUTHENTICATION_URL, '_self');
        }}>
            Sign in with Wikipedia
        </Button>
      </div>
      <div className="button-container">
        <button className="alternative-link"
                onClick={ () => {
            window.open(AUTHENTICATION_URL + "?guest", '_self');
         }}>
           Sign in as a guest
        </button>
      </div>
    </>
  );
}

function TermsAndConditions(props) {
  if (props.show) {
    return (
      <Modal show={props.show}>
        <Modal.Header>Terms and Conditions</Modal.Header>
        <Modal.Body>
          <TOSContent
            sideWikiEditorPluginName={content.SIDE_WIKI_EDITOR_PLUG_IN_NAME}
            facebookTosLink={content.FACEBOOK_TOS_LINK}
            facebookDataPolicyLink={content.FACEBOOK_DATA_POLICY}>
          </TOSContent>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.toggleShowState}>Acknowledged</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  return (<></>);
}

function App() {
  const [wikiAuth, setWikiAuth] = useState(true);
  const [showTerms, setShowTerms] = useState(false);

  function handleLogin() {
    if (wikiAuth) {
      setWikiAuth(false);
    } else {
      setWikiAuth(true);
    }
  }

  function handleTerms() {
    if (showTerms) {
      setShowTerms(false);
    } else {
      setShowTerms(true);
    }
  }

  return (
    <div>
      <Navbar bg="light" expand="lg" className="justify-content-center" variant="light">
        <Navbar.Brand>Login Page</Navbar.Brand>
      </Navbar>
      <div className="container">
      <TermsAndConditions content={content.termsAndConditions} show={showTerms} toggleShowState={ handleTerms }/>
      <center><img src={require('./assets/side_logo.png')} style={{height: "auto", width: "50%"}} /></center>

        <p> Welcome to Side, a research project investigating AI to support and empower Wikipedia editors.
        To read more about the overarching goals of this project, please see our Wikimedia article on <a href="https://meta.wikimedia.org/wiki/Research:Machine_Learning_Assisted_Wikipedia_Editing">ML-Assisted Wikipedia Editing</a>.
        </p>

        <p>
          Over the last year we have developed an AI-based Wikipedia citation recommender and, with this website, you'll have the opportunity to see and judge some of its citation recommendations.
          <b> We would greatly appreciate your help in assessing the quality of our system. </b>
          In particular, we will present you with passages and a highlighted claim from a recent version of Wikipedia.
          Accompanying the claim is a pair of two possible citations that may support it.
          You will be asked which of the citations is a better source to support the given claim, if any.
          Note, <b>no changes will be made to Wikipedia as part of this process</b>, but we will use the data collected as part of a scientific publication describing our system.
          We've run a number of annotation campaigns already, but really want to gauge what real Wikipedia users think of the system.
          Note, the interface indicates if you select a citation suggested by our system and, if you wish, <b>you can use the provided information to update Wikipedia and improve its verifiability</b> (please use the <b>#side</b> hashtag in the edit comment).
          All code and models powering our citation recommender system are publicly available in our <a href="https://github.com/facebookresearch/side">GitHub repository</a>.
        </p>

        <p>(Why Logins?) To make sure we don't show you the same examples more than once, we need you to provide a username.
          We ask you to login using your Wikipedia account so we can make sure that legitimate Wikipedia users assess the quality of our system. However, you can chose to login as a guest (but in that case you might see the same example multiple times and your assessments will not contribute to our study).
          If you login using your Wikipedia account, the only data we will access is Wikipedia username, which we will store anonymously (using an irreversible hash function so that we cannot trace back to your actual username) - no other authorization will be requested.
          By signing in, you are confirming that you have read and agree with our <button className="terms-and-conditions" onClick={ handleTerms }>Terms and Conditions</button>.
        </p>

      </div>
      <div className="container">
        <Login wikiAuth={wikiAuth} onClickHandler={handleLogin} />
      </div>
    </div>
  );
}

export default App;
