import React, {useState, useEffect} from 'react'
import {Card, Button} from 'react-bootstrap'
import './Selections.css'

function Selection(props) {
    const increment = 1024;
    let [preText, setPreText] = useState("");
    let [postText, setPostText] = useState("");

    function loadMorePre(e) {
        e.stopPropagation();

        let len = Math.min(preText.length + increment, props.preText.length);
        setPreText(props.preText.substring(props.preText.length - len));
    }

    function loadMorePost(e) {
        e.stopPropagation();

        let len = Math.min(postText.length + increment, props.postText.length);
        setPostText(props.postText.substring(0, len));
    }

    useEffect(() => {
        setPreText("");
        setPostText("");
    }, [props.text]);

    return (
        <Card className="selection" id={props.id} onClick={(e) => props.parent.updateSelection(props.id)}>
            <Card.Body>
                <div id="rbutton" style={{width: "10%"}}>
                    <input type="radio" id={props.id + "_radio"} className="selectionRadio" name="selection-group"
                           value={props.value}/>
                </div>
                <Card.Text id="card1">

                    <b style={{fontSize: "1.2em"}}>{props.title}</b><br/>
                    <i style={{fontSize: "10pt"}}>{props.url}</i>
                    <div style={{textAlign: "center"}}>
                        <Button variant="link"
                                onClick={loadMorePre}
                                disabled={preText.length === props.preText.length}
                                style={{textDecoration: "none", fontSize: "10pt", textTransform: "uppercase"}}
                        >
                            show more &uarr;
                        </Button>
                    </div>
                    {(preText.length < props.preText.length) ? "..." : ""}
                    {preText}
                    {props.text}
                    {postText}
                    {(postText.length < props.postText.length) ? "..." : ""}
                    <br/>
                    <div style={{textAlign: "center"}}>

                        <Button variant="link"
                                onClick={loadMorePost}
                                disabled={postText.length === props.postText.length}
                                style={{textDecoration: "none", fontSize: "10pt", textTransform: "uppercase"}}
                        >
                            show more &darr;
                        </Button>
                    </div>
                </Card.Text>

            </Card.Body>
        </Card>
    );
}

export default Selection
