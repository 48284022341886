import { render } from "react-dom";
import { BrowserRouter, Routes, Route} from "react-router-dom";
import App from "./App";
import Main from "./routes/Main";
import 'bootstrap/dist/css/bootstrap.min.css';


const rootElement = document.getElementById("root");
render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="main" element={<Main />} />
    </Routes>
  </BrowserRouter>,
  rootElement
);