import React from 'react';
import Passage from './Passage';
import Selection from './Selections';
import {Container, Row, Col, Button, Card, Modal} from 'react-bootstrap';
import './AnnotationInterface.css';

const API_URL = 'https://verifier.sideeditor.com/api';

class AnnotationInterface extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: null,
            startTime: new Date().getTime(),
            showSubmitModal: false,
            modal_title: "",
            modal_content: "",
        };
    }

    componentDidMount() {
        this.fetchNextDocument();
    }

    fetchNextDocument() {
        this.setState({showSubmitModal: false});
        fetch(`${API_URL}/get_document`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                'credentials': 'include',
                'cors': 'true',
            })
            .then(res => res.json())
            .then(json => {
                this.setState({ data: json, startTime: new Date().getTime() });
                this.clearSelections();
            })            
            .catch(rejected => {
                window.location = '/';
            });
    }

    sendAnnotation() {

        const endTime = new Date().getTime();
        const annotationTime = endTime - this.state.startTime;
        const that = this;

        fetch(`${API_URL}/annotate`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            'credentials': 'include',
            'cors': 'true',
            body: JSON.stringify({
                document_id: this.state.data.id,
                annotation: this.state.selection,
                annotation_time: annotationTime,
            })
        }).then(response => response.json())
        .then(
            data => { 
                let wikipedia_title = this.state.data.meta.wikipedia_title.replace(' ', '_');
                let sentences = this.state.data.meta.sentences;
                let claim = sentences[sentences.length - 1];
                let output = null;
                if (this.state.selection == "selection1"){
                    output = this.state.data.output_A[0].provenance[0];
                }
                else{ // if (this.state.selection == "selection2"){
                    output = this.state.data.output_B[0].provenance[0];
                };

                let title = output.title
                let url = output.url

                if (data.none){
                    const message = "One of the citations was present in Wikipedia.<br> \
                        If you wish, go at:<br><br> <a href='https://en.wikipedia.org/wiki/"+wikipedia_title+"'>https://en.wikipedia.org/wiki/"+wikipedia_title+"</a><br><br> \
                        and add a <a href='https://en.wikipedia.org/wiki/Template:Failed_verification'>{{Failed verification}}</a> template \
                        for the claim:<br><br><q>"+claim+"</q><br><br> \
                        if the citation and the claim are still there (the article might have been updated in the meantime).<br> \
                        If you update Wikipedia, please add the <b>#side</b> hashtag in the edit comment.";
                    this.setState({modal_content: message});
                    this.setState({modal_title: "Potential Failed Verification"});
                }
                else if (data.side){
                    const message = "The citation you selected was automatically suggested by Side.<br> \
                        If you wish, go at:<br><br> <a href='https://en.wikipedia.org/wiki/"+wikipedia_title+"'>https://en.wikipedia.org/wiki/"+wikipedia_title+"</a><br><br> \
                        and update the citation for the claim:<br><br><q>"+claim+"</q><br><br> \
                        with the following: <br><br> \
                        &lt;ref&gt;{{cite web |url=<q>"+url+"</q> |title=<q>"+title+"</q>}}&lt;/ref&gt; <br><br> \
                        if the citation and the claim are still there (the article might have been updated in the meantime).<br> \
                        If you update Wikipedia, please add the <b>#side</b> hashtag in the edit comment.";
                    this.setState({modal_content: message});
                    this.setState({modal_title: "Potential Citation Improvement"});
                }
                else{
                    const message = "The citation you selected is already in Wikipedia.";
                    this.setState({modal_content: message});
                    this.setState({modal_title: "Nothing To Improve"});
                }
                this.setState({showSubmitModal: true});
                //that.fetchNextDocument();
            }
        );
    }

    clearSelections() {
        this.setState({"selection": null});
        const selections = document.querySelectorAll(".selection");
        selections.forEach(function (sel) {
            sel.classList.remove("selected");
        });
        const radioButtons = document.querySelectorAll(".selectionRadio");
        radioButtons.forEach(function (sel) {
            sel.checked = false;
        });
    }

    updateSelection(selected_id) {
        this.clearSelections();
        this.setState({"selection": selected_id});
        document.getElementById(selected_id).classList.add("selected");
        document.getElementById(selected_id + "_radio").checked = true;
    }

    render() {
        const {data} = this.state;

        const openCollapsible = () => {
            this.setState(prevState => ({
                opened: !prevState.opened,
            }));
        };

        if (data == null) {
            return <Container>Loading...</Container>;
        }

        let output1 = data.output_A[0].provenance[0];
        output1.pre_text = data.output_A[0].pre_text;
        output1.post_text = data.output_A[0].post_text;

        let output2 = data.output_B[0].provenance[0];
        output2.pre_text = data.output_B[0].pre_text;
        output2.post_text = data.output_B[0].post_text;

        return (
            <Container fluid="sm">

                <div id="wrapper" style={{margin: "20pt 0 20pt 0"}}>

                    <div className="instructions_button">
                        <Button className="btn" onClick={openCollapsible}>
                            Detailed Instructions
                        </Button>

                        {this.state.opened ?
                            <div id="instructions">
                                <ol>
                                    <li> Read the Wikipedia snippet containing the claim which is <b>highlighted</b>.
                                    </li>
                                    <li> Read each passage, expanding them by selecting SHOW MORE, if more context is
                                        needed.
                                    </li>
                                    <li> Finally, select which of the two provided passages you consider to be a better
                                        citation
                                        source for the highlighted text on the left, or select 'None of the above'. Keep
                                        in
                                        mind that Wikipedia citations should be based on reliable, <a
                                            href="https://en.wikipedia.org/wiki/Wikipedia:Independent_sources">independent</a>,
                                        published sources with a reputation for fact-checking and accuracy.
                                    </li>
                                    <li> Click the submit button to submit your answers.</li>
                                </ol>
                            </div> : null
                        }
                    </div>

                    <br></br>

                    <p><i>Select the source on the right that you think is more appropriate to fill the <sup><b>[citation needed]</b></sup> tag on the left.</i></p>


                    <Row id="main">
                        <Col id="passage">
                            <Passage
                                title={data.meta.wikipedia_title}
                                text={data.input}
                                passage_data={data.structured_input}
                            />
                        </Col>
                        <Col id="selections">
                            <h1>Sources</h1>
                            <div className="selection1" label="selection1">
                                <Selection parent={this} id="selection1" title={output1.title} text={output1.text}
                                           preText={output1.pre_text} postText={output1.post_text} url={output1.url}
                                           value={output1.val}/>
                            </div>

                            <div className="selection2" label="selection2">
                                <Selection parent={this} id="selection2" title={output2.title} text={output2.text}
                                           preText={output2.pre_text} postText={output2.post_text} url={output2.url}
                                           value={output2.val}/>
                            </div>

                            <div className="selection3" label="selection3">
                                <Card className="selection" id="selection3"
                                      onClick={(e) => this.updateSelection("selection3")}>
                                    <Card.Body>
                                        <div id="rbutton" style={{width: "10%"}}>
                                            <input type="radio" id="selection3_radio" className="selectionRadio"
                                                   name="selection-group" value="none"/>
                                        </div>
                                        <Card.Text id="card1">
                                            <b style={{fontSize: "1.2em", display: "block"}}>
                                            None of the above</b>
                                        Only select this if you think that none of the sources matches the citation at
                                        all.
                                    </Card.Text>

                                </Card.Body>
                            </Card>
                </div>

                <div className="selection-container">
                    <Button variant="primary" id="submit" disabled={!this.state.selection}
                            onClick={this.sendAnnotation.bind(this)}>Submit</Button>
                </div>
            </Col>
    </Row>
    </div>
    <Modal
        backdrop="static"
        show={this.state.showSubmitModal}
        onHide={this.fetchNextDocument.bind(this)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
        <Modal.Header closeButton>
            <Modal.Title><div dangerouslySetInnerHTML={{__html: this.state.modal_title}}/></Modal.Title>
        </Modal.Header>
        <Modal.Body> <div dangerouslySetInnerHTML={{__html: this.state.modal_content}}/></Modal.Body>
        <Modal.Footer>
            <Button variant="primary" onClick={this.fetchNextDocument.bind(this)}>
                Next
            </Button>
        </Modal.Footer>
    </Modal>
    </Container>
    );
    }
}

export default AnnotationInterface;
